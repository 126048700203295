.CodeMirror-merge {
  position: relative;
  border: 1px solid #ddd;
  white-space: pre;
}

.CodeMirror-merge, .CodeMirror-merge .CodeMirror {
  height: 600px;
}

.CodeMirror-merge-2pane .CodeMirror-merge-pane {
  width: 47%;
}

.CodeMirror-merge-2pane .CodeMirror-merge-gap {
  width: 6%;
}

.CodeMirror-merge-3pane .CodeMirror-merge-pane {
  width: 31%;
}

.CodeMirror-merge-3pane .CodeMirror-merge-gap {
  width: 3.5%;
}

.CodeMirror-merge-pane {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}

.CodeMirror-merge-pane-rightmost {
  position: absolute;
  right: 0px;
  z-index: 1;
}

.CodeMirror-merge-gap {
  z-index: 2;
  display: inline-block;
  height: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  position: relative;
  background: #f8f8f8;
}

.CodeMirror-merge-scrolllock-wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
}

.CodeMirror-merge-scrolllock {
  position: relative;
  left: -50%;
  cursor: pointer;
  color: #555;
  line-height: 1;
}

.CodeMirror-merge-scrolllock:after {
  content: "\21db\00a0\00a0\21da";
}

.CodeMirror-merge-scrolllock.CodeMirror-merge-scrolllock-enabled:after {
  content: "\21db\21da";
}

.CodeMirror-merge-copybuttons-left, .CodeMirror-merge-copybuttons-right {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  line-height: 1;
  background-color: lightgrey;
}

.CodeMirror-merge-copy {
  position: absolute;
  cursor: pointer;
  color: #579480;
  z-index: 3;
}

.CodeMirror-merge-copy-reverse {
  position: absolute;
  cursor: pointer;
  color: #FF8983;
}

.CodeMirror-merge-copybuttons-left .CodeMirror-merge-copy {
  left: 2px;
}

.CodeMirror-merge-copybuttons-right .CodeMirror-merge-copy {
  right: 2px;
}

.CodeMirror-merge-r-inserted, .CodeMirror-merge-l-inserted {
  background-color: #FF8983;
}

.CodeMirror-merge-r-deleted, .CodeMirror-merge-l-deleted {
  Background-color: #6BDFB8;
}

.CodeMirror-merge-r-chunk {
  background: #FFC4C1;
}

/*.CodeMirror-merge-r-chunk-start { border-top: 1px solid #ee8; }
.CodeMirror-merge-r-chunk-end { border-bottom: 1px solid #ee8; }*/
.CodeMirror-merge-r-connect {
  fill: #ffffe0;
  stroke: #ee8;
  stroke-width: 1px;
}

.CodeMirror-merge-l-chunk {
  background: #eef;
}

.CodeMirror-merge-l-chunk-start {
  border-top: 1px solid #88e;
}

.CodeMirror-merge-l-chunk-end {
  border-bottom: 1px solid #88e;
}

.CodeMirror-merge-l-connect {
  fill: #eef;
  stroke: #88e;
  stroke-width: 1px;
}

.CodeMirror-merge-l-chunk.CodeMirror-merge-r-chunk {
  background: #dfd;
}

.CodeMirror-merge-l-chunk-start.CodeMirror-merge-r-chunk-start {
  border-top: 1px solid #4e4;
}

.CodeMirror-merge-l-chunk-end.CodeMirror-merge-r-chunk-end {
  border-bottom: 1px solid #4e4;
}

.CodeMirror-merge-collapsed-widget:before {
  content: "(...)";
}

.CodeMirror-merge-collapsed-widget {
  cursor: pointer;
  color: #88b;
  background: #eef;
  border: 1px solid #ddf;
  font-size: 90%;
  padding: 0 3px;
  border-radius: 4px;
}

.CodeMirror-merge-collapsed-line .CodeMirror-gutter-elt {
  display: none;
}

.CodeMirror-gutters {
  border: none;
}

.CodeMirror-gutter {
  color: rgb(153, 153, 153);
  background: #fff;
}

.CodeMirror-merge-gap {
  background: #fff;
  border-right: none;
}

.CodeMirror-linenumber {
  text-align: center;
}

.CodeMirror-merge-pane-rightmost .CodeMirror-merge-r-chunk {
  background: #B5EFDB;
}