@import './src/scss/variables';
@import './src/scss/app';

.widget {
	&__editor {
		text-align: left;
	}

	&__button-wrap {
		display: flex;
		justify-content: space-around;
	}
}

@import "codemirror.css";
@import "merge.css";